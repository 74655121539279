import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle, UrlSegment } from '@angular/router';
import { areEqualObjects } from '@png/common/core/utils/are-equal-objects';

import { LoginComponent } from '../features/auth/login/login.component';

import { appRouteDataSchema } from './app-route';

type RouteStorageObject = Readonly<{

	/** Activated route snapshot. */
	snapshot: ActivatedRouteSnapshot;

	/** Detached route handle. */
	handle: DetachedRouteHandle;
}>;

/**
	* Whether route is reusable or not.
	* @param data Activated route data.
	*/
function isReusable(data: ActivatedRouteSnapshot['data']): boolean {
	const typedData = appRouteDataSchema.parse(data);
	return typedData.reusable ?? false;
}

/**
	* We should reset our cached routes, when user logout.
	* Due, to avoid circular dependencies (because, we can't use services that use router inside this class),
	* We mean that when the user went to the login page, the user logged out of the app.
	* @param component Current component.
	*/
function isLoginComponent(component: ActivatedRouteSnapshot['component']): boolean {
	return component === LoginComponent;
}

/**
	* Custom route reuse strategy allows us improve the performance of an Angular application
	* by allowing the router to reuse components and avoid recreating them every time a user navigates to a new route.
 */
export class AppRouteReuseStrategy implements RouteReuseStrategy {
	private storedRoutes: Record<string, RouteStorageObject> = {};

	/** @inheritdoc */
	public shouldDetach(route: ActivatedRouteSnapshot): boolean {
		if (isLoginComponent(route.component)) {
			this.clearStoredRoutes();
			return false;
		}

		return isReusable(route.data);
	}

	/** @inheritdoc */
	public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
		const id = this.createRouterIdentifier(route);
		if (isReusable(route.data) && id.length > 0) {
			this.storedRoutes[id] = { handle, snapshot: route };
		}
	}

	/** @inheritdoc */
	public shouldAttach(route: ActivatedRouteSnapshot): boolean {
		const id = this.createRouterIdentifier(route);
		const storedRoute = this.storedRoutes[id];
		const attachable = route.routeConfig != null && storedRoute != null;

		if (!attachable) {
			return false;
		}

		const areParamsEqual = areEqualObjects(route.params, storedRoute.snapshot.params);
		return areParamsEqual;
	}

	/** @inheritdoc */
	public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
		const id = this.createRouterIdentifier(route);

		if (route.routeConfig && this.storedRoutes[id]) {
			return this.storedRoutes[id].handle;
		}

		return null;
	}

	/** @inheritdoc */
	public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
		return future.routeConfig === current.routeConfig;
	}

	private clearStoredRoutes(): void {
		this.storedRoutes = {};
	}

	private createRouterIdentifier(route: ActivatedRouteSnapshot): string {
		const segments: UrlSegment[][] = route.pathFromRoot.map(r => r.url);
		const subpaths = ([] as UrlSegment[])
			.concat(...segments)
			.map(segment => segment.path);

		return `${segments.length}-${subpaths.join('/')}`;
	}
}
