import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { AppConfig } from './app.config';

type Icon = {

	/** Name for icon. */
	readonly name: string;

	/** Name of svg file of icon.*/
	readonly path: string;
};

/** Service to register custom icons. */
@Injectable({
	providedIn: 'root',
})
export class IconsService {

	private readonly matIconRegistry = inject(MatIconRegistry);

	private readonly domSanitizer = inject(DomSanitizer);

	private readonly appConfig = inject(AppConfig);

	private readonly platformId = inject(PLATFORM_ID);

	private readonly icons: readonly Icon[] = [
		{
			name: 'visibility_eye',
			path: 'visibility-eye.svg',
		},
		{
			name: 'visibility_eye_off',
			path: 'visibility-eye-off.svg',
		},
		{
			name: 'promo',
			path: 'promo.svg',
		},
		{
			name: 'call',
			path: 'call.svg',
		},
		{
			name: 'mail',
			path: 'mail.svg',
		},
		{
			name: 'batches',
			path: 'batches.svg',
		},
		{
			name: 'settings',
			path: 'settings.svg',
		},
		{
			name: 'info',
			path: 'info.svg',
		},
		{
			name: 'check-circle',
			path: 'check-circle.svg',
		},
		{
			name: 'order_stock',
			path: 'order-stock.svg',
		},
		{
			name: 'library',
			path: 'library.svg',
		},
		{
			name: 'help',
			path: 'help.svg',
		},
		{
			name: 'edit',
			path: 'edit.svg',
		},
		{
			name: 'refresh',
			path: 'refresh.svg',
		},
		{
			name: 'delete',
			path: 'delete.svg',
		},
		{
			name: 'close',
			path: 'close.svg',
		},
		{
			name: 'import',
			path: 'import.svg',
		},
		{
			name: 'download',
			path: 'download.svg',
		},
		{
			name: 'share',
			path: 'share.svg',
		},
		{
			name: 'top_bottom_left_right',
			path: 'top-bottom-left-right.svg',
		},
		{
			name: 'top_bottom_right_left',
			path: 'top-bottom-right-left.svg',
		},
		{
			name: 'bottom_top_left_right',
			path: 'bottom-top-left-right.svg',
		},
		{
			name: 'bottom_top_right_left',
			path: 'bottom-top-right-left.svg',
		},
		{
			name: 'graphic',
			path: 'graphic.svg',
		},
		{
			name: 'pdf',
			path: 'pdf.svg',
		},
		{
			name: 'jpg',
			path: 'jpg.svg',
		},
		{
			name: 'eps',
			path: 'eps.svg',
		},
	];

	/** Register icons. */
	public registerIcons(): void {
		const domain = isPlatformServer(this.platformId) ? this.appConfig.deploymentUrl : '';

		this.icons.forEach(({ name, path }) => {
			this.registerIcon(name, `${domain}assets/icons/${path}`);
		});
	}

	private registerIcon(name: string, path: string): void {
		this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
	}
}
