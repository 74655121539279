import { importProvidersFrom } from '@angular/core';
import { provideRouter, RouteReuseStrategy, withInMemoryScrolling } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from '@png/common/core/interceptors/auth.interceptor';
import { AppConfig } from '@png/common/core/services/app.config';
import { MatNativeDateModule } from '@angular/material/core';
import { ValidationTokenInterceptor } from '@png/common/core/interceptors/validation-token.interceptor';
import { DatePipe } from '@angular/common';

import { appRoutes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { WebAppConfig } from './app/features/shared/web-app.config';
import { provideWebAppRoutes } from './app/features/shared/web-route-paths';
import { AppRouteReuseStrategy } from './app/features/shared/configs/app-route-reuse-strategy';

const httpInterceptorProviders = [
	// The validation interceptor should be before the auth interceptor, otherwise token won't be validated.
	{ provide: HTTP_INTERCEPTORS, useClass: ValidationTokenInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(BrowserModule),
		...httpInterceptorProviders,
		{ provide: AppConfig, useClass: WebAppConfig },
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		provideRouter(appRoutes, withInMemoryScrolling({ anchorScrolling: 'enabled' })),
		provideWebAppRoutes(),
		{ provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },

		/**
		 * Datepicker throws an NullInjectorError: No provider for DateAdapter.
		 * For some reason this is not picked up for standalone modals.
		 * So provide it here resolve it, due this one will make it available for the whole application.
		 * @see Https://github.com/angular/components/issues/28186.
		 */
		importProvidersFrom(MatNativeDateModule),
		DatePipe,
	],
})
	.catch(err => console.error(err));
