import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { RouterOutlet } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { fromEvent } from 'rxjs';
import { IconsService } from '@png/common/core/services/icons.service';
import { WINDOW } from '@png/common/core/tokens/window-token';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { LoadingService } from '@png/common/core/services/loading.service';

/** Root component. */
@Component({
	selector: 'pngw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet, MatProgressBarModule],
	providers: [
		{
			provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
			useValue: { color: 'primary' },
		},
	],
})
export class AppComponent implements OnInit {
	private readonly loadingService = inject(LoadingService);

	/** @inheritdoc */
	protected readonly isLoading = toSignal(this.loadingService.isLoading$);

	private readonly destroyRef = inject(DestroyRef);

	private readonly window = inject(WINDOW);

	private readonly iconsService = inject(IconsService);

	public constructor() {
		this.iconsService.registerIcons();
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		// When we use custom `AppRouteReuseStrategy` and use browser navigation we get some artifacts with material overlays.
		// So we need use this workaround to avoid getting overlay artifacts on the page after browser navigation.
		fromEvent(this.window, 'popstate').pipe(
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe(() => {
				const cdkOverlayContainer = document.querySelector(
					'.cdk-overlay-container>.cdk-overlay-backdrop.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing',
				) as HTMLElement;
				cdkOverlayContainer?.click();
			});
	}
}
