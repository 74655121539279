/**
	* Recursive compare function for two objects.
	* @param base First object.
	* @param other Another object.
	*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function areEqualObjects(base: any, other: any): boolean {
	for (const baseProperty in { ...base, ...other }) {
		if (!Object.prototype.hasOwnProperty.call(other, baseProperty)) {
			return false;
		}

		switch (typeof base[baseProperty]) {
			case 'object':
				if (
					typeof other[baseProperty] !== 'object' ||
						!areEqualObjects(base[baseProperty], other[baseProperty])
				) {
					return false;
				}
				break;
			case 'function':
				if (typeof other[baseProperty] !== 'function' ||
						base[baseProperty].toString() !== other[baseProperty].toString()
				) {
					return false;
				}
				break;
			default:
				if (base[baseProperty] !== other[baseProperty]) {
					return false;
				}
		}
	}
	return true;
}
