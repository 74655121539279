import { Data, Route } from '@angular/router';
import { z } from 'zod';

/** Validation schema for custom app route data object. */
export const appRouteDataSchema = z.object({

	/** Whether the route should be reused or not. */
	reusable: z.boolean().optional(),
});

/** App route data object. */
export type AppRouteData = z.infer<typeof appRouteDataSchema> & Data;

/** Custom app route type. */
export type AppRoute = {

	/** @inheritdoc */
	data?: AppRouteData;

	/** @inheritdoc */
	children?: AppRoute[];
} & Route;

/** App routes. */
export type AppRoutes = AppRoute[];
